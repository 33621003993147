import React from 'react';
import { Helmet } from 'react-helmet-async';
import { graphql, PageRendererProps } from 'gatsby';

import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';

interface TemplateProps {
  title: string;
  content: string;
  contentComponent?: typeof Content | typeof HTMLContent;
  location?: string;
  schema?: object;
}

interface PageProps extends PageRendererProps {
  data: {
    markdownRemark: {
      frontmatter: {
        type: string;
        title: string;
        description: string;
      };
      html: string;
    };
  };
}

export function ContentPageTemplate({
  title,
  content,
  contentComponent,
  location,
  schema,
}: TemplateProps) {
  const PageContent = contentComponent || Content;

  return (
    <Layout location={location} title={title} schema={schema}>
      <section className="section">
        <div className="container">
          <PageContent className="content" content={content} />
        </div>
      </section>
    </Layout>
  );
}

export default function ContentPage({ data, location }: PageProps) {
  const { frontmatter, html: content } = data.markdownRemark;

  return (
    <>
      <Helmet>
        <title>{frontmatter.title}</title>
        <meta name="description" content={frontmatter.description} />
      </Helmet>
      <ContentPageTemplate
        title={frontmatter.title}
        content={content}
        contentComponent={HTMLContent}
        schema={{
          '@type': data.markdownRemark.frontmatter.type,
          name: data.markdownRemark.frontmatter.title,
          description: data.markdownRemark.frontmatter.description,
          url: location.href,
        }}
        location={location.href}
      />
    </>
  );
}

export const contentPageQuery = graphql`
  query ContentPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        type
        title
        description
      }
      html
    }
  }
`;
